import React, { useContext, useState, useEffect } from "react"
import "../../styles/datepicker.css"
import Calendar from "react-calendar"
import { navigate } from "gatsby"
import { QuoteContext } from "../../Context/QuoteContext"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import { updateField } from "../../Context/Actions"
import { format } from "date-fns"
import { sv } from "date-fns/locale"
import { useTheme } from "emotion-theming"
import Icon from "../../components/Icon/Icon"
import Overlay from "../../components/GlobalAssets/Overlay/overlay"
import Modal from "../../components/GlobalAssets/Modal/modal"
import LinkButton from "../../components/GetQuote/Fields/Buttons/LinkButton"
import styled from "@emotion/styled"
import { saveQuoteSendPin } from "../../Context/Actions"
import ButtonLoader from "../../components/GlobalAssets/ButtonLoader"

const SelectStartDatePage = () => {
  const { state, dispatch } = useContext(QuoteContext)
  const [confirm, setConfirm] = useState(false)
  const [date, setDate] = useState(
    state.fields.startDate.fieldValue === ""
      ? new Date()
      : new Date(state.fields.startDate.fieldValue)
  )
  const theme = useTheme()
  const maxDate = new Date(new Date().setMonth(new Date().getMonth() + 11))

  useEffect(() => {
    if (state.fields.startDate.valid && confirm) {
      switch (state.quote.result.customerStatus) {
        case "new":
          navigate(`/bilforsakring/ange-mobilnummer`, { replace: true })
          break
        case "active":
          dispatch(saveQuoteSendPin({ state: state }))
          break
        default:
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.fields.startDate.valid])

  if (!state.hasQuote) {
    if (typeof window === "undefined") {
      return null
    }
    navigate("/")
    return null
  }

  const handleChange = date => {
    setDate(date)

    setConfirm(true)
  }

  const handleOnConfirm = () => {
    dispatch(
      updateField({
        name: "startDate",
        value: format(date, "yyyy-MM-dd"),
        valid: true,
        dirty: true,
      })
    )
  }

  return (
    <Layout>
      <SEO title="evoli - Välj startdag för din bilförsäkring" noIndex={true} />
      <QuoteContainer>
        <QuoteHeroContent>
          <Icon
            name="calendar"
            bgColor={theme.colors.CardContainer.iconBackground}
            iconColor={theme.colors.CardContainer.iconColor}
            iconSize="25"
          />

          <h1>Ange startdag för bilförsäkringen</h1>
          <p>
            Bilförsäkringen kan börja gälla direkt eller på valfri dag i
            framtiden.
          </p>

          <Calendar
            locale="sv-SE"
            onChange={date => handleChange(date)}
            value={date}
            minDetail="month"
            minDate={new Date()}
            maxDate={maxDate}
            showNeighboringMonth={false}
            activeStartDate={new Date()}
          />
        </QuoteHeroContent>
      </QuoteContainer>

      <Overlay show={confirm} />

      <Modal show={confirm}>
        <Icon
          name="calendar"
          iconSize="25"
          bgColor={theme.colors.CardContainer.iconBackground}
          iconColor={theme.colors.CardContainer.iconColor}
        />

        <h1>{format(new Date(date), "iiii, d MMM  yyyy", { locale: sv })}</h1>

        <p>Är detta rätt datum du vill försäkra bilen ifrån? </p>

        <Button type="button" onClick={handleOnConfirm}>
          {state.isLoading ? <ButtonLoader /> : "Ja, det är rätt"}
        </Button>

        <LinkButton
          text="Stäng"
          type="button"
          onClick={() => setConfirm(false)}
        />
      </Modal>
    </Layout>
  )
}

export default SelectStartDatePage

const QuoteContainer = styled.section`
  background: ${props => (props.bgColor ? props.bgColor : "none")};
  width: 100%;
  padding: 50px 40px 80px 40px;
  min-height: 570px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    margin: 0;
    text-align: left;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    padding: 50px 40px 80px 40px;
    margin: 0;
    text-align: left;
    flex-direction: column;
    min-height: calc(100vh - 46px);
  }

  @media (max-width: 600px) and (min-width: 414px) {
    padding: 30px 20px;
    margin: 0;
    text-align: left;
    flex-direction: column;
    justify-content: flex-start;
  }

  @media (max-width: 375px) {
    padding: 30px 16px;
    margin: 0;
    text-align: left;
    flex-direction: column;
    min-height: calc(100vh - 46px);
  }

  @media (max-width: 320px) {
    padding: 40px 15px;
    margin: 0;
    text-align: left;
    flex-direction: column;
  }
`

const QuoteHeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  h1,
  p {
    max-width: 305px;
  }

  h1 {
    margin: 0;
    margin-top: 10px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #ff4984;

    span {
      display: block;
      color: #5558ea;
      margin-top: 10px;
    }
  }

  p {
    margin-top: 4px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: #63638f;
    margin-bottom: 0;

    max-width: 240px;
  }
`
const Button = styled.button`
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(107, 60, 231, 1) 5%,
    rgba(87, 55, 213, 1) 100%
  );
  color: #ffffff;
  border-radius: 0.3rem;
  font-size: 16px;
  font-weight: bold;
  height: 48px;
  border: 0;

  :disabled {
    opacity: 0.3;

    :hover {
      cursor: not-allowed;
    }
  }

  :hover {
    background: linear-gradient(
      180deg,
      rgba(107, 60, 231, 0.9) 5%,
      rgba(87, 55, 213, 0.9) 100%
    );
    cursor: pointer;
  }
`
